import { v4 as uuidv4 } from "uuid";

export const setColumnWidth = (col: keyof CampaignsType | string): string => {
  switch (col) {
    case "display_name":
    case "brand_name":
      return "w-[248px]";
    case "status":
    case "metrics":
      return "w-[156px]";
    case "data_activation":
    case "platforms":
      return "w-[204px]";
    case "total_spend":
    case "report":
      return "w-[148px]";
    case "start_date":
    case "end_date":
      return "w-[168px]";
    case "actions":
      return "w-[20px]";
    default:
      return "w-[100px]";
  }
};

export const beautifyStatus = (status: string): string => {
  switch (status) {
    case "draft":
      return "As a Draft";
    default:
      return status;
  }
};

/**
 * Transforms the inbound campaign data from the API response to fit the application's state structure.
 * It parses and formats platform, time period, and budget information.
 *
 * @param {CampaignResponseType} inboundData - The campaign data from the backend.
 * @param {boolean} isDuplicate - A flag indicating whether the data is being duplicated.
 * @returns {CampaignState} - The transformed campaign data ready to be stored in the application state.
 */
export function transformInboundData(inboundData: CampaignResponseType, isDuplicate = false): CampaignState {
  // Initialize an empty platforms object to store the transformed platform data.
  const platforms: Record<string, PlatformDataType> = {};
  // Map and transform the time periods for each platform.
  inboundData.platforms.forEach((platform) => {
    const timePeriods: TimePeriodsType[] = platform.time_periods.map((timePeriod) => ({
      id: timePeriod.id, // Use the time period's id
      startDate: new Date(timePeriod.start_date), // Convert the start date string to a Date object
      endDate: new Date(timePeriod.end_date), // Convert the end date string to a Date object
      budget: formatBudgetCurrency(timePeriod.budget.toString()), // Format the budget as a currency string
      // If custom pacing and audience budget distribution are enabled, format audience budgets.
      audiencesBudgetCustom:
        platform.budget_distribution && platform.pacing === "custom_pacing" && timePeriod.audiences
          ? timePeriod.audiences.map((audience) =>
              audience.budget !== null ? formatBudgetCurrency(audience.budget?.toString() ?? "") : "",
            )
          : [], // Otherwise, set an empty array.
    }));
    // Get the audience IDs from the first time period (if available).
    const selectedAudiences = platform.time_periods[0]?.audiences;
    // Format the platform's total budget as a currency string.
    const budget = formatBudgetCurrency(platform.budget.toString());
    // Find the platform name by matching platform.id or generate a fallback name.
    const platformName =
      inboundData.platforms.find((p) => p.id === platform.id)?.platform_name ?? `Platform-${platform.id}`;
    // Add the transformed platform data to the platforms object.
    platforms[platformName] = {
      id: platform.id, // Use the platform ID from the inbound data
      completed: true, // Mark the platform as completed
      firstObjective: platform.primary_objective, // Set the first objective
      secondObjective: {
        parentObjective: platform.primary_objective, // Set the parent objective (same as the first objective)
        objective: platform.secondary_objective, // Set the second objective
      },
      selectedAudiences, // Set the audience IDs for this platform
      budgetAndSchedule: {
        audienceBudgetDistribution: platform.budget_distribution, // Whether audience budget distribution is enabled
        // Set start and end dates for the platform
        startDate: platform.time_periods[0]?.start_date ? new Date(platform.time_periods[0].start_date) : null,
        endDate: platform.time_periods[platform.time_periods.length - 1]?.end_date
          ? new Date(platform.time_periods[platform.time_periods.length - 1].end_date)
          : null,
        // Temporary start and end dates based on pacing mode
        tempStartDateEven: platform.pacing === "pace_evenly" ? new Date(platform.time_periods[0]?.start_date) : null,
        tempStartDateCustom:
          platform.pacing === "custom_pacing" ? new Date(platform.time_periods[0]?.start_date) : null,
        tempEndDateEven:
          platform.pacing === "pace_evenly"
            ? new Date(platform.time_periods[platform.time_periods.length - 1]?.end_date)
            : null,
        tempEndDateCustom:
          platform.pacing === "custom_pacing"
            ? new Date(platform.time_periods[platform.time_periods.length - 1]?.end_date)
            : null,
        // Set the main budget
        budget,
        // Temporary budgets based on pacing and audience budget distribution
        tempBudgetEven: !platform.budget_distribution && platform.pacing === "pace_evenly" ? budget : "",
        tempBudgetCustom: !platform.budget_distribution && platform.pacing === "custom_pacing" ? budget : "",
        tempBudgetAudienceEven: platform.budget_distribution && platform.pacing === "pace_evenly" ? budget : "",
        tempBudgetAudienceCustom: platform.budget_distribution && platform.pacing === "custom_pacing" ? budget : "",
        // Set the pacing mode (custom or evenly paced)
        pacing: platform.pacing,
        // Store time periods for custom pacing, otherwise an empty array for evenly paced platforms
        timePeriods,
        tempTimePeriods: platform.pacing === "custom_pacing" ? timePeriods : [],
        // Set the audiences' budget for evenly paced platforms
        audiencesBudgetEven:
          platform.budget_distribution && platform.pacing === "pace_evenly" && platform.time_periods[0]?.audiences
            ? platform.time_periods[0].audiences.map((audience) =>
                formatBudgetCurrency(audience.budget?.toString() ?? ""),
              )
            : [],
      },
      selectedRegionPage: null,
      selectedSortOrder: platform.audience_sorted_order,
      selectedSortedColumn: platform.audience_sorted_column,
    };
  });

  const platformNames = Object.keys(platforms);
  const displayId = platformNames.length > 0 ? platformNames[platformNames.length - 1] : "";
  return {
    id: inboundData.id,
    step: isDuplicate ? 0 : 6,
    loading: false,
    brands: [],
    platforms: [],
    displayId,
    selectedBrand: inboundData.brand_id,
    selectedCampaignName: !isDuplicate ? inboundData?.campaign_display_name ?? "" : "",
    selectedDuplicateId: "",
    campaignCreation: platforms,
  };
}

/**
 * Transforms the application state into the correct format for submitting to the API.
 * It maps platforms, time periods, budgets, and audiences from the store's state to the required format.
 *
 * @param {CampaignState} storeData - The state data from the store, including campaign and platform details.
 * @returns {CampaignResponseType} - The formatted data ready for submission to the API.
 */
export function transformOutboundData(storeData: CampaignState): CampaignResponseType {
  // Map the platforms from the store state, filtering only the completed ones.
  const platforms = Object.entries(storeData.campaignCreation)
    .filter(([_, platform]) => platform.completed)
    .map(([platformName, platform]) => {
      // Map each platform's time periods and calculate the budget
      let timePeriods: TimePeriodActivationType[] = platform.budgetAndSchedule.timePeriods.map((timePeriod) => {
        // Calculate the budget for each time period based on audience distribution
        const periodBudget = platform.budgetAndSchedule.audienceBudgetDistribution
          ? timePeriod.audiencesBudgetCustom.reduce(
              (sum, audienceBudget) => sum + parseFormattedCurrency(audienceBudget),
              0,
            )
          : parseFormattedCurrency(timePeriod.budget); // Default to the overall budget if distribution is disabled
        // Return the transformed time period
        return {
          id: timePeriod.id, // Include the time period ID if in edit mode
          start_date: (timePeriod.startDate as Date).toISOString().split("T")[0], // Convert start date to YYYY-MM-DD
          end_date: (timePeriod.endDate as Date).toISOString().split("T")[0], // Convert end date to YYYY-MM-DD
          budget: periodBudget, // The calculated budget for this period
          // Map audiences and their respective budgets
          audiences: platform.selectedAudiences.map((audience, index) => ({
            ...audience,
            budget: platform.budgetAndSchedule.audienceBudgetDistribution
              ? platform.budgetAndSchedule.pacing === "custom_pacing"
                ? parseFormattedCurrency(timePeriod.audiencesBudgetCustom[index])
                : parseFormattedCurrency(platform.budgetAndSchedule.audiencesBudgetEven[index])
              : null, // Null if audience budget distribution is disabled
          })),
        };
      });
      // Ensure the time_periods array has at least one entry (fallback if empty)
      if (timePeriods.length === 0) {
        timePeriods = [
          {
            start_date: (platform.budgetAndSchedule.startDate as Date).toISOString().split("T")[0], // Fallback start date
            end_date: (platform.budgetAndSchedule.endDate as Date).toISOString().split("T")[0], // Fallback end  date
            budget: parseFormattedCurrency(platform.budgetAndSchedule.budget), // Fallback to overall platform budget
            audiences: platform.selectedAudiences.map((audience, index) => ({
              ...audience,
              budget: platform.budgetAndSchedule.audienceBudgetDistribution
                ? parseFormattedCurrency(platform.budgetAndSchedule.audiencesBudgetEven[index])
                : null, // Null if audience budget distribution is disabled
            })),
          },
        ];
      }
      // Calculate the total budget for the platform
      const totalBudget = parseFormattedCurrency(platform.budgetAndSchedule.budget);
      // Find the corresponding platform data in storeData.platforms by matching platformName
      const platformData = storeData.platforms.find((p) => p.name === platformName);
      return {
        id: platform.id, // Include platform ID if in edit mode
        platform_id: platformData?.id ?? undefined, // Use the platform ID from the state or fallback to undefined
        primary_objective: platform.firstObjective.split(" ")[0], // Extract the primary objective
        secondary_objective: platform.secondObjective.objective.split(" ")[0], // Extract the secondary objective
        pacing: platform.budgetAndSchedule.pacing, // Set pacing type (custom or evenly)
        budget: totalBudget, // Set the total budget
        budget_distribution: platform.budgetAndSchedule.audienceBudgetDistribution, // Set budget distribution flag
        time_periods: timePeriods, // Add the time periods for this platform
        audience_sorted_column: platform.selectedSortedColumn,
        audience_sorted_order: platform.selectedSortOrder,
      };
    });
  // Return the final transformed campaign data
  return {
    id: storeData.id, // Include campaign ID if in edit mode
    name: storeData.selectedCampaignName, // Set campaign name
    brand_id: storeData.selectedBrand, // Set the brand ID
    platforms, // Include all the transformed platforms
  };
}

/**
 * Sums up an array of budget strings, parses them into numbers, and returns the total as a formatted currency string.
 *
 * @param {string[]} budgetItems - An array of budget strings to be summed.
 * @returns {string} - The total budget formatted as a currency string.
 */
export function calculateTotalBudget(budgetItems: string[]): string {
  return formatBudgetCurrency(
    budgetItems
      .reduce((acc, item) => {
        return acc + parseFormattedCurrency(item);
      }, 0)
      .toString(),
  );
}

/**
 * Retrieves the first start date and the last end date from an array of time periods.
 * Defaults to the current date if no valid date is found in the time periods.
 *
 * @param {TimePeriodsType[]} timePeriods - An array of time periods containing start and end dates.
 * @returns {{ startDate: Date, endDate: Date }} - An object with the first start date and the last end date.
 */
export function getFirstAndLastDates(timePeriods: TimePeriodsType[]): { startDate: Date; endDate: Date } {
  // Get the first start date (default to new Date() if not available)
  const startDate = timePeriods[0]?.startDate ?? new Date();
  // Get the last end date (default to new Date() if not available)
  const endDate = timePeriods[timePeriods.length - 1]?.endDate ?? new Date();
  return { startDate, endDate };
}

/**
 * Adds a new custom time period to the existing array of time periods, generating a unique ID.
 *
 * @param {TimePeriodsType[]} timePeriods - The current array of time periods.
 * @param {Omit<TimePeriodsType, "id">} newTimePeriod - The new time period object without an ID.
 * @returns {TimePeriodsType[]} - A new array of time periods with the newly added time period.
 */
export function addCustomTimePeriod(
  timePeriods: TimePeriodsType[],
  newTimePeriod: Omit<TimePeriodsType, "id">,
): TimePeriodsType[] {
  return [...timePeriods, { ...newTimePeriod, id: uuidv4() }];
}

/**
 * Removes a custom time period from the array by its index.
 *
 * @param {TimePeriodsType[]} timePeriods - The current array of time periods.
 * @param {number} index - The index of the time period to be removed.
 * @returns {TimePeriodsType[]} - The updated array of time periods with the specified time period removed.
 */
export function removeCustomTimePeriod(timePeriods: TimePeriodsType[], index: number): TimePeriodsType[] {
  return timePeriods.filter((_, i) => i !== index);
}

/**
 * Restores the appropriate budget based on the pacing mode and audience budget distribution.
 * Depending on whether the pacing is 'custom' or 'even', and whether budgets are distributed,
 * it returns the correct budget to be used.
 *
 * @param {string} pacing - The pacing mode, either "custom_pacing" or "pace_evenly".
 * @param {boolean} audienceBudgetDistribution - Flag indicating if the budget is distributed across audiences.
 * @param {string} tempBudgetCustom - Custom budget for non-distributed audiences.
 * @param {string} tempBudgetAudienceCustom - Custom budget for distributed audiences.
 * @param {string} tempBudgetEven - Even pacing budget for non-distributed audiences.
 * @param {string} tempBudgetAudienceEven - Even pacing budget for distributed audiences.
 * @returns {string} - The restored budget based on pacing and distribution settings.
 */
export function restorePacingValues(
  pacing: string,
  audienceBudgetDistribution: boolean,
  tempBudgetCustom: string,
  tempBudgetAudienceCustom: string,
  tempBudgetEven: string,
  tempBudgetAudienceEven: string,
): string {
  // Logic to determine the budget based on pacing and audience distribution
  if (pacing === "custom_pacing") {
    return audienceBudgetDistribution ? tempBudgetAudienceCustom : tempBudgetCustom;
  } else {
    return audienceBudgetDistribution ? tempBudgetAudienceEven : tempBudgetEven;
  }
}
